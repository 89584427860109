.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none; 
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
#theme-switch {
  position: relative;
  width: fit-content;
  height: fit-content;
  touch-action: pan-x;
  user-select: none;
}
#theme-switch input {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}
#theme-switch .switch-track {
  height: 40px;
  width: 90px;
  border-radius: 30px;
  transition: all 0.2s ease 0s;
  background: linear-gradient(180deg, #21A0DA -28.9%, #9DD382 100%), #FFFFFF;
}
#theme-switch .switch-check, #theme-switch .switch-x {
  bottom: 0px;
  top: 41%;
  position: absolute;
  margin: 0 0px;
  transform: translate(0px, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
#theme-switch .switch-icon svg {
  font-size: 20px;
  color: #FFAB08;
}
#theme-switch .switch-check {
left: 15px;
opacity: 0;
transition: opacity 0.25s ease 0s;
}
#theme-switch .switch-x {
  right: 15px;
  opacity: 1;
  transition: opacity 0.25s ease 0s;
}
#theme-switch .switch-thumb {
  background-color: #fafafa;
  height: 30px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 50px;
  border-width: 1px;
  border-style: solid;
  border-color: #ffffff;
  border-image: initial;
  border-radius: 20px;
  transition: all 0.25s ease 0s;
}

[darkmode] #theme-switch .switch-check {
  opacity: 1;
}
[darkmode] #theme-switch .switch-x {
  opacity: 0;
}
[darkmode] #theme-switch .switch-thumb {
  left: 36px;
}
